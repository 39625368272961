<template>
  <div class="backtop" v-if="show" @click="gotoTop()">
    <div>
      <img
        style="width: 24px; height: 24pxl; margin-top: 14px"
        src="../assets/gotop.png"
      />
    </div>
    <!-- <div>置顶</div> -->
  </div>
</template>

<script>
export default {
  name: "BackTop",
  data() {
    return {
      show: false,
    };
  },
  props: {},
  mounted() {
    window.addEventListener("scroll", this.showIcon);
  },
  methods: {
    showIcon() {
      
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 200
      ) {
        this.show = true;
      } else {
        this.show = false;
      }
    },

    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
         clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      } else {
         clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      }
      return clientHeight;
    },
    gotoTop(){
        window.scrollTo(0, 0);

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.backtop {
  position: fixed;
  right: 8%;
  bottom: 100px;
  width: 46px;
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(9, 9, 9, 0.05);
  border-radius: 23px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
  }
}
</style>
