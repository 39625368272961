const routes = [
  {
    path: '/', 
    name: '',
    redirect:'/index', // 默认路由
  },
  {
    path: '/index', 
    title:'首页',
    name: 'Home',
    component: () => import('@/pages/home/index.vue')
  },
  {
    path: '/about', 
    title:'关于我们',
    name: 'about',
    component: () => import('@/pages/about/index.vue'),
    redirect:'/about/profile', // 默认路由
    children: [
      {
        path: '/about/profile',
        component: () => import('@/pages/about/profile/index.vue')
      },
      {
        path: '/about/development',
        component: () => import('@/pages/about/development/index.vue')
      },
      {
        path: '/about/organization',
        component: () => import('@/pages/about/organization/index.vue')
      },
      {
        path: '/about/association',
        component: () => import('@/pages/about/association/index.vue')
      },
      {
        path: '/about/system',
        component: () => import('@/pages/about/system/index.vue')
      },
      {
        path: '/about/contact',
        component: () => import('@/pages/about/contact/index.vue')
      },
    ]
  },
  {
    path: '/newscenter', 
    title:'新闻中心',
    name: 'newscenter',
    component: () => import('@/pages/newscenter/index.vue'),
    redirect:'/newscenter/list/:id/:showType', // 默认路由
    children: [
      {
        path: '/newscenter/list/:id/:showType',
        component: () => import('@/pages/newscenter/list/index.vue')
      },
      {
        path: '/newscenter/detail/:id',
        component: () => import('@/pages/newscenter/detail/index.vue')
      },
     
    
    ]
  },
  {
    path: '/introduction', 
    title:'项目介绍',
    name: 'introduction',
    component: () => import('@/pages/introduction/index.vue'),
    redirect:'/introduction/list/:id/:showType', // 默认路由
    children: [
      {
        path: '/introduction/list/:id/:showType',
        component: () => import('@/pages/introduction/list/index.vue')
      },
      {
        path: '/introduction/detail/:id',
        component: () => import('@/pages/introduction/detail/index.vue')
      },
      {
        path: '/introduction/detail2/:id',
        component: () => import('@/pages/introduction/detail2/index.vue')
      },
      {
        path: '/introduction/online/:id',
        component: () => import('@/pages/introduction/online/index.vue')
      },
      {
        path: '/introduction/payback/:id',
        component: () => import('@/pages/introduction/payback/index.vue')
      },
    ]
  },
  {
    path: '/named', 
    title:'慈善文化',
    name: 'named',
    component: () => import('@/pages/named/index.vue'),
    redirect:'/named/list/:id/:showType', // 默认路由
    children: [
      {
        path: '/named/list/:id/:showType',
        component: () => import('@/pages/named/list/index.vue')
      },
      {
        path: '/named/detail/:id',
        component: () => import('@/pages/named/detail/index.vue')
      },
    ]
  },
  {
    path: '/support', 
    title:'支持我们',
    name: 'support',
    component: () => import('@/pages/support/index.vue'),
    redirect:'/support/givingway', // 默认路由
    children: [
      {
        path: '/support/givingway',
        component: () => import('@/pages/support/givingway/index.vue')
      },
      {
        path: '/support/donations',
        component: () => import('@/pages/support/donations/index.vue')
      },
      {
        path: '/support/paper',
        component: () => import('@/pages/support/paper/index.vue')
      },
      {
        path: '/support/volunteers',
        component: () => import('@/pages/support/volunteers/index.vue')
      },
      {
        path: '/support/givingdetail',
        component: () => import('@/pages/support/givingdetail/index.vue')
      },
    ]
  },
  {
    path: '/dynamic', 
    title:'党建动态',
    name: 'dynamic',
    component: () => import('@/pages/dynamic/index.vue'),
    redirect:'/dynamic/list/:id/:showType', // 默认路由
    children: [
      {
        path: '/dynamic/list/:id/:showType',
        component: () => import('@/pages/dynamic/list/index.vue')
      },
      {
        path: '/dynamic/detail/:id/:showType',
        component: () => import('@/pages/dynamic/detail/index.vue')
      },
     
    
    ]
  },
  {
    path: '/info', 
    title:'信息公开',
    name: 'info',
    component: () => import('@/pages/info/index.vue'),
    redirect:'/info/list/:id/:showType', // 默认路由
    children: [
      {
        path: '/info/list/:id/:showType',
        component: () => import('@/pages/info/list/index.vue')
      },
      {
        path: '/info/detail/:id',
        component: () => import('@/pages/info/detail/index.vue')
      },
    ]
  },
]
export default routes