<template>
  <div class="header">
    <div class="headerBg"></div>
    <div class="headerContent">
      <div class="headerBox">
        <div class="hearderImg">
          <img src="../assets/header/logo2x.png" />
          <!-- <div style="font-size:12px">人人做慈善，行行可慈善</div>
          <div style="font-size:12px">依靠社会做好慈善，做好慈善为社会！</div> -->
        </div>
        <div class="headerNav">
          <div
            v-for="item in routes"
            :key="item.name"
            @click="goPath(item.path)"
            :class="{ active: routesPath.includes(item.path), 'nav-cli': true }"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routes from "@/routes";
export default {
  name: "HeaDer",
  data() {
    return {
      routes: routes.slice(1, 9),
      routesPath: "/",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val,oldVal);
      this.routesPath =  '/'+val.fullPath.split('/')[1]
      },
      // 深度观察监听
      deep: true,
    },
  },
  props: {},
  mounted() {
    let _that = this;
    setTimeout(() => {
      _that.routesPath = _that.$route.path;
    }, 200);
    this.$nextTick(() => {});
  },
  methods: {
    goPath(path) {
      // if (this.routesPath == path) return;
      window.location.href = path
      // this.$router.push(path);
      // this.routesPath = path;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
  position: relative;
  height: 80px;
  z-index: 9999;
}
.headerBg {
  position: absolute;
  height: 80px;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.9;
  left: 0;
  top: 0;
}
.headerContent {
  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
  .headerBox {
    width: 1200px;
    margin: auto;
  }
  .hearderImg {
    float: left;
    img {
      width: 217px;
      height: 50px;
      margin-top: 15px;
    }
  }
  .headerNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 0 0 60px;
    color: #333333;
    font-size: 16px;
    cursor: pointer;
    .nav-cli {
      padding: 0px 20px;
      border-radius: 5px;
      color: 16px;
      line-height: 36px;
      font-weight: 400;
    }
    .active {
      background: #3fc4bb;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 500;
    }
  }
}
</style>
