<template>
  <div class="footer">
    <div class="footerBox">
      <div class="footText">
        <div class="footleft">
          <div style="padding: 40px 0 10px 0; font-size: 24px; color: #333333">
            联系我们
          </div>
          <div class="text">地址：{{detail.Address}}</div>
          <div class="text">捐赠服务热线：{{detail.DonatePhone}}</div>
          <div class="text">邮编：{{detail.PostCode}}</div>
          <div class="text">传真：{{detail.Fax}}</div>
          <div class="text">后台管理：<a href="http://manage.hzscszh.org.cn/#/home" target="_blank">立即进入</a></div>
        </div>
        <div class="footright">
          <div>
            <div><img :src="detail.WBFileUrl" /></div>
            <div>微博二维码</div>
          </div>
          <div>
            <div><img :src="detail.DYFileUrl" /></div>
            <div>抖音二维码</div>
          </div>
          <div>
            <div><img :src="detail.SPHFileUrl" /></div>
            <div>视频号二维码</div>
          </div>
          <div>
            <div><img :src="detail.WXFileUrl" /></div>
            <div>公众号二维码</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div>版权所有:杭州市慈善总会保留所有权利</div>
        <div><img src="../assets/footer/police.png" />浙ICP备2022006599号</div>
      </div>
    </div>
  </div>
</template>

<script>
import apiAbout from "@/apis/about";

export default {
  name: "FooTer",
  data() {
    return {
       detail:{}
    };
  },
  props: {},
  mounted() {
    this.GetBuyer()
  },
  methods: {
    GetBuyer() {
      apiAbout
        .GetBuyer({ articleType: 2 })
        .then((res) => {
          this.detail = res.datas;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
  background-color: #f3f3f3;
  .footerBox {
    height: 300px;
    width: 1200px;
    margin: auto;
  }
  .footText {
    height: 240px;
    .footleft {
      width: 500px;
      text-align: left;
      float: left;
      .text {
        color: #333333;
        opacity: 0.9;
        font-size: 16px;
        margin-top: 5px;
      }
    }
    .footright {
      display: flex;
      align-items: center;
       height: 240px;
      width: 600px;
      float: left;
      justify-content: space-between;
      color: #333333;
      opacity: 0.9;
      text-align: center;
      img{
        display: block;
        margin-bottom: 10px;
        width: 114px;
        height: 114px;
      }
    }
  }
  .bottom {
    font-size: 14px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    padding: 0 200px;
    opacity: 0.5;
    img {
      display: inline-block;
      width: 24px;
      padding: 0px 8px 0 0;
      vertical-align: sub;
    }
  }
}
</style>
