import request from '@/utils/request';
//关于我们
//设置个对象，就不用一个个暴露了，直接暴露对象
let apiAbout = {};

 //机构制度 articleType 1:机构介绍 2:发展历程 3:组织架构 4:机构章程 5:机构制度
apiAbout.LoadArticleInfo = function (params) {
  return request.post('/api/Article/LoadArticleInfo', params) 
}

//联系我们
apiAbout.GetBuyer = function (params) {
  return request.post('/api/Buyer/GetBuyer', params) 
}
 

//组织框架_人员分类
apiAbout.LoadOrganize = function (params) {
  return request.post('/api/Organize/LoadOrganize', params) 
}

//联系我们
apiAbout.LoadOrganizePeople = function (params) {
  return request.post('/api/OrganizePeople/LoadOrganizePeople', params) 
}

//暴露出这个对象
export default apiAbout;
