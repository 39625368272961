<template>
  <a-config-provider :locale="zhCN">
    <Header />
    <div class="middlebox">
      <router-view></router-view>
    </div>
    <Footer/>
    <back-top></back-top>
  </a-config-provider>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BackTop from "@/components/BackTop.vue";

import zhCN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";

import "moment/locale/zh-cn";

moment.locale("zh-cn");

export default {
  name: "App",
  data() {
    return {
      zhCN: zhCN,
    };
  },
  components: {
    Header,
    Footer,
    BackTop,
  },
  created() {
   
  },
};
</script>

<style>
#app {
  font-family: '微软雅黑','宋体',PingFangSC-Regular, PingFang SC, Helvetica, Arial, Avenir,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.middlebox {
  min-height: 600px;
  background-color: #fff;
  margin-top: -90px;
}
.mouseHand {
  cursor: pointer;
  width: 168px;
  text-align: center;
  font-size: 20px;
  justify-content: center;
  display: flex;
}
.swiper-pagination-bullet-active {
  background-color: #fff !important;
  width: 20px !important;
  border-radius: 10px !important;
}

/* .div::-webkit-scrollbar {
  display: none;
  width: 0;
}

.div::-webkit-scrollbar-track {
  display: none;
   width: 0;
}

.div::-webkit-scrollbar-thumb {
  display: none;
   width: 0;
} */
.full-modal .ant-modal-body {
  min-height: 400px;
  max-height: 800px;
  overflow: auto;
}
</style>
