import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import routes from './routes'
// import less from 'less'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import vueSwiper from 'vue-awesome-swiper'
import  * as Icons from '@ant-design/icons-vue';

// import '../node_modules/swiper/swiper-bundle.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import 'swiper/css/swiper.css'

import moment from 'moment';
import 'moment/dist/locale/zh-cn';
moment.locale('zh-cn');

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

// 格式化时间
function FormatDate (time) {
  return moment(time).format("YYYY-MM-DD")
}
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
})
router.afterEach(() => {
  //document.title = to.meta.title || '硅谷系统'
})
router.beforeEach(() => {
  window.scrollTo(0, 0);
  //document.title = to.meta.title || '硅谷系统'
})
const app = createApp(App)
app.config.globalProperties.$FormatDate = FormatDate
// app.use(less)
app.use(Antd)

const icons = Icons;
for (const i in icons) {
    // 全局注册一下组件
    app.component(i, icons[i])
}
app.use(router)
app.use(vueSwiper)
app.mount('#app')
